"use client"

import styles from "./InsightsVideos.module.scss"
import Container from "../Container/Container"
import Row from "../Row/Row"
import Col from "../Col/Col"
import Spacer from "../Spacer/Spacer"
import { useState, useEffect } from "react"
import Modal from "../Modal/Modal"
import InsightItem from "@/components/Insights/InsightItem"
import Link from "next/link"

export default function InsightsVideos({
  id,
  className,
  desc,
  content,
  moreLink,
  theme,
}) {
  const mainVideo = content[0]
  const videosReduced = content
  videosReduced.shift()

  const reducedVideoArray = (startIndex, endIndex, withImg = true) => {
    const videosArray = videosReduced.slice(startIndex, endIndex)

    return videosArray.map((item, index) => {
      return (
        <InsightItem
          key={index}
          item={item}
          index={index}
          withImg={withImg}
          className={styles.videos__item}
        />
      )
    })
  }

  return (
    <section
      className={`${styles.videos} ${
        theme == "home" ? styles["videos--home"] : ""
      }`}
      id={id}
    >
      <Container className={styles.videos__container}>
        <div className={styles.videos__top}>
          <div className={styles.videos__head}>
            <h3>{desc.title}</h3>
            <a href="/posts">View more</a>
          </div>
          {/* <p dangerouslySetInnerHTML={{ __html: desc.text }}></p> */}
        </div>
        <div className={styles.videos__row}>
          <div className={styles.videos__main}>
            {mainVideo && (
              <InsightItem
                className={styles.videos__item}
                isMain
                item={mainVideo}
                theme={theme}
              />
            )}
          </div>
          <div className={styles.videos__list}>{reducedVideoArray(0, 4)}</div>
          <div className={`${styles.videos__list} ${styles.videos__list_last}`}>
            {reducedVideoArray(4, 8)}
          </div>
        </div>
      </Container>
    </section>
  )
}
