import RegularButton from "@/components/RegularButton/RegularButton"
import {
  getDateWithTime,
  getDateWithShortMonth,
  getGMT,
  isPastEvent,
} from "@/utils/datehelpers.js"
import styles from "./ListItem.module.scss"

export default function ListItem({ item }) {
  const link = `/events/${item.slug}/`

  return (
    <a className={styles.listitem} target="_blank" href={link}>
      <div className={styles.listitem__img}>
        {!isPastEvent(item.date_time, item.time_zone) && (
          <div
            className={styles.listitem__shortdate}
            dangerouslySetInnerHTML={{
              __html: getDateWithShortMonth(item.date_time),
            }}
          ></div>
        )}

        {item.image_webp ? (
          <img src={item.image_webp} alt={`${item.title} image`} />
        ) : (
          item.image && <img src={item.image} alt={`${item.title} image`} />
        )}
        <div className={styles.listitem__labels}>
          {item.place && (
            <div
              className={styles.listitem__label}
              dangerouslySetInnerHTML={{ __html: item.place.title }}
            ></div>
          )}

          {item.type === "online" && (
            <div className={`${styles.listitem__label} ${styles.online}`}>
              LIVE
            </div>
          )}
        </div>
      </div>
      <div className={styles.listitem__bottom}>
        <div className={styles.listitem__bottom_content}>
          <div
            className={styles.listitem__date}
            dangerouslySetInnerHTML={{
              __html: `${getDateWithTime(item.date_time)} ${getGMT(
                item.time_zone
              )}`,
            }}
          ></div>
          <div
            href={link}
            dangerouslySetInnerHTML={{ __html: item.title }}
            className={styles.listitem__title}
          ></div>
          {item.place && (
            <div
              className={`${styles.listitem__location} ${
                item.type === "online" ? styles.online : ""
              }`}
            >
              {item.type === "offline" ? (
                <div
                  dangerouslySetInnerHTML={{ __html: item.place.address }}
                ></div>
              ) : (
                <span>
                  live streamed from&nbsp;{item.place && item.place.title}
                </span>
              )}
            </div>
          )}
        </div>
        <div className={styles.listitem__btns}>
          {!isPastEvent(item.date_time, item.time_zone) ? (
            <RegularButton
              justText={true}
              text="Sign up"
              className={`${styles.listitem__btn} ${styles.listitem__btn_signup}`}
            ></RegularButton>
          ) : (
            <RegularButton
              btnStyle="secondary"
              justText={true}
              text="Learn more"
              className={styles.listitem__btn}
            ></RegularButton>
          )}
        </div>
      </div>
    </a>
  )
}
