"use client"

import DATA from "@/data/main"
import DATA_POLAND from "@/data/events/poland/en"
// import Speakers from "../Speakers/Speakers"
import Speakers from "../Speakers/Speakers"
// import VideoSlider from "../Video/VideoSlider"
import About from "../AboutOld/About"
import JoinSubmit from "../JoinSubmit/JoinSubmit"
import Intro from "../Intro/Intro"
import { default as ListEvents } from "@/components/Events/List/List"
import ReadingRoomBlock from "../ReadingRoom/ReadingRoom"
import InsightsVideos from "../Poland/InsightsVideos"

export default function MainPage({ banner, posts, events }) {
  const speakersGrid = {
    cols: 24,
    sm: 8,
    md: 4.8,
    xl: 4,
  }

  return (
    <>
      {/* <Intro item={DATA.pastVideos.videos[0]} /> */}
      {/* <PolandIntroHome /> */}
      {banner ? <Intro item={banner} /> : null}

      {events.length > 0 ? (
        <ListEvents items={events} showMore></ListEvents>
      ) : null}

      {posts.length > 0 ? (
        <InsightsVideos
          desc={DATA_POLAND.insightsVideos}
          content={posts}
          id="insights"
          moreLink={"/insights/"}
          theme="home"
        />
      ) : null}
      {/* <VideoSlider
        items={DATA.pastVideos.videos.slice(
          1,
          DATA.pastVideos.videos.length - 1
        )}
      /> */}
      <ReadingRoomBlock isMainPage />
      <About />
      <Speakers speakers={DATA.speakers} />
      <JoinSubmit />
    </>
  )
}
