import { Link } from "@/i18n/routing"
import { forwardRef } from "react"

import Container from "@/components/Container/Container"
import styles from "./List.module.scss"
import ListItem from "./ListItem"

const List = forwardRef(function List(
  { items, showMore, withPagination },
  ref
) {
  return (
    <section
      className={`${styles.list} ${
        withPagination ? styles.list_withpagination : ""
      }`}
      ref={ref}
    >
      <Container>
        <div className={styles.list__header}>
          <h3>All Events</h3>{" "}
          {showMore && (
            <Link className={styles.list__more} href="/events/">
              View more
            </Link>
          )}
        </div>
        <ul>
          {items.map((item, index) => {
            return (
              <li key={index}>
                <ListItem item={item} />
              </li>
            )
          })}
        </ul>
      </Container>
    </section>
  )
})

export default List
