const DATA = {
  signupText: "Join us online",
  header: {
    slogan: "We bring together leading<br>market experts.",
    menu: [
      {
        name: "Events",
        url: "events",
      },
      {
        name: "Insights",
        url: "posts",
      },
      {
        name: "Reading room",
        url: "reading-room/",
      },
      // {
      //   name: "Horizon Panel",
      //   url: "#speakers",
      // },
      {
        name: "About",
        url: "#about",
      },
    ],
  },
  event: {
    home: "Home",
    pageName: "Horizon Poland 2023",
    langs: [
      {
        url: "/poland2023/",
        name: "English",
      },
      {
        url: "/poland2023-pl/",
        name: "Polish",
      },
    ],
    date: "14 September 2023 3:30 PM<span> (GMT+2)</span>",
    name: "Shifting Power: Eastern Europe&apos;s Impact on Geopolitics, Trade, and&nbsp;Financial Markets",
    desc: "This is the third in our Horizon programme series and we are proud to be in Warsaw, Poland at the historic Palace on the Isle. With this event, we are honouring its rich history of “Thursday dinners” or afternoon banquets which provided an arena for the open exchange of ideas for Polish writers, scientists and intellectuals. The movers and shakers of the day gathered here at the invitation of the King to discuss and debate the most topical issues affecting the country and the world.<br><br>EXANTE Horizon events are free of charge.",
    place: "Palace on the Isle, Royal Łazienki, Warsaw, Poland",
  },
  insightsVideos: {
    title: "Horizon Insights",
    moreText: "View more",
    text: "Check out the videos from our previous Horizon events at the London Stock Exchange in the UK and in Limassol, Cyprus earlier this year. Discover what panellists thought would be on the horizon for the global economy and financial markets in 2023. Do you think they got it right or wrong?",
    videos: [
      {
        url: "https://exantestatic.s3.eu-west-1.amazonaws.com/docs/Horizon-Cyprus-Report-summary-fin-web.pdf",
        title: "Horizon Cyprus 2023 summary",
        image: "/images/insights/images/cyprus2023/horizon-cyprus-report.jpg",
        date: "09.07.2023",
        text: "EXANTE’s second Horizon panel discussion explored the key market drivers in the first quarter of 2023 and highlighted the key opportunities and risks that investors should consider throughout the remainder of 2023.",
        download: true,
      },
      {
        url: "826917336?h=baae0e0745",
        title:
          "When growth is outperforming, look ahead of the curve, says Hannah Gooch-Peters",
        text: "Global Equity",
        image: "/images/video/cover-hannah.jpg",
        date: "15.05.2023",
      },
      {
        url: "826917278?h=d63f4be354",
        title: "Find out why resilience will be more important than efficiency",
        text: "Director at Sapienta",
        image: "/images/video/cover-fiona.jpg",
        date: "15.05.2023",
      },
      {
        url: "826917081?h=847c56afa1",
        title:
          "The relationship between growth and inflation; what it means for asset classes",
        text: "Head of Global Strategy",
        image: "/images/video/cover-arnab.jpg",
        date: "15.05.2023",
      },
      {
        url: "825890540?h=af9a23f2e7",
        title:
          "MiCA finally opens up the market in Europe. What will this mean for banking?",
        text: "CEO of STASIS",
        image: "/images/video/cover-gregory.jpg",
        date: "11.05.2023",
      },
      {
        url: "822905582?h=7c634c553b",
        title:
          "Our second EXANTE Horizon event was live from Cyprus. View the highlights here",
        image: "/images/video/cover-all.jpg",
        date: "02.05.2023",
      },
      {
        url: "806363432?h=c56f0a39b1",
        title:
          "View highlights from our integral Horizon event at the London Stock Exchange",
        image: "/images/video/cover-all.jpg",
        date: "09.03.2023",
      },
      {
        url: "802587093?h=3546d0f97b",
        title:
          "David Buik discusses why it’ll be vital to stock pick and sector pick equities",
        image: "",
        date: "27.02.2023",
      },
      {
        url: "801594536?h=0889fabdd0",
        title:
          "Watch the full video of our integral EXANTE Horizon event at the London Stock Exchange. ",
        image: "",
        date: "23.02.2023",
      },
      {
        url: "796575690?h=cd46803b9d",
        title: "Why risk management will be more important than return seeking",
        image: "",
        date: "07.02.2023",
      },
      {
        url: "796575656?h=0c207d6a7a",
        title:
          "Stay in quality and avoid cyclicality warns Rathbones' Bryn Jones",
        image: "",
        date: "07.02.2023",
      },
      {
        url: "796575592?h=cde5a538b3",
        title:
          "Consumer sentiment will see markets bottom, says Schroders’ Nick Kissak",
        image: "",
        date: "07.02.2023",
      },

      // {
      //   url: "825890540?h=af9a23f2e7",
      //   title: "Gregory Klumov",
      //   text: "CEO of STASIS",
      //   image: "/images/video/cover-gregory.jpg",
      //   date: "09.07.2023",
      // },
      // {
      //   url: "826917081?h=847c56afa1",
      //   title: "Arnab Das",
      //   text: "Head of Global Strategy",
      //   image: "/images/video/cover-arnab.jpg",
      //   date: "09.07.2023",
      // },
      // {
      //   url: "826917336?h=baae0e0745",
      //   title: "Hannah Gooch-Peters",
      //   text: "Global Equity",
      //   image: "/images/video/cover-hannah.jpg",
      //   date: "09.07.2023",
      // },
      // {
      //   url: "826917278?h=d63f4be354",
      //   title: "Fiona Mullen",
      //   text: "Director at Sapienta",
      //   image: "/images/video/cover-fiona.jpg",
      //   date: "09.07.2023",
      // },
      // {
      //   url: "825890540?h=af9a23f2e7",
      //   title: "Gregory Klumov",
      //   text: "CEO of STASIS",
      //   image: "/images/video/cover-gregory.jpg",
      //   date: "09.07.2023",
      // },
      // {
      //   url: "826917081?h=847c56afa1",
      //   title: "Arnab Das",
      //   text: "Head of Global Strategy",
      //   image: "/images/video/cover-arnab.jpg",
      //   date: "09.07.2023",
      // },
      // {
      //   url: "826917336?h=baae0e0745",
      //   title: "Hannah Gooch-Peters",
      //   text: "Global Equity",
      //   image: "/images/video/cover-hannah.jpg",
      //   date: "09.07.2023",
      // },
      // {
      //   url: "826917278?h=d63f4be354",
      //   title: "Fiona Mullen",
      //   text: "Director at Sapienta",
      //   image: "/images/video/cover-fiona.jpg",
      //   date: "09.07.2023",
      // },
    ],
  },
  speakers_title: "The Horizon Panel",
  speakers: [
    {
      image: "/images/speakers/ketziora.jpg",
      imagex2: "/images/speakers/ketziora.jpg",
      position: "Head of Fixed Income,<br>Pekao TFI",
      name: "Dariusz Kędziora",
      text: "Dariusz directly manages long-duration funds as well as heads a team of fixed-income fund managers. His expertise spans across global EM rates, FX and credit. He has over 16 years of experience managing fixed-income portfolios. Before joining Pekao TFI in 2020, he worked for Aviva Investors in London in the emerging markets debt team. He was responsible for managing EM blended and local currency strategies. In 2013, 2014 and 2022, together with the team, he won the awards for the best fixed-income fund in Poland. ",
      showText: false,
    },
    {
      image: "/images/speakers/Arnab.jpg",
      imagex2: "/images/speakers/Arnab@2x.jpg",
      position: "Head of Global strategy EMEA, <br>Invesco",
      name: "Arnab Das",
      text: "Arnab joined Invesco in 2015 as Head of EM macro research for Invesco Fixed Income. He began his career in finance in 1992 and has served as Co-head of Economic Research & Strategy at Roubini Global Economics; Co-head of Global Economics & Strategy, Head of Global FX Research, and Head of EM Economics & Strategy at Dresdner Kleinwort; and Head of EEMEA Research at JP Morgan.",
      showText: false,
    },
    {
      image: "/images/Simona@2x.png",
      imagex2: "/images/Simona@2x.png",
      position: "Head of Global PR,<br>EXANTE",
      name: "Simona Stankovska",
      text: "<strong>EXANTE’s Simona Stankovska will moderate the debate, asking the&nbsp;experts where they consider the&nbsp;opportunities to lie in 2023 and&nbsp;beyond</strong>.<br>Simona has more than 11 years’ experience as a journalist and writer. She has worked in the Financial services sector since 2009 and has covered both private wealth and retail asset management.",
      showText: false,
    },
    {
      image: "/images/speakers/unknown-speaker.jpg",
      imagex2: "/images/speakers/unknown-speaker@2x.jpg",
      position: "Lorem ipsum<br>dolor sit amet",
      name: "Lorem ipsum",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vitae ligula non turpis vulputate lacinia. Nullam augue ante, interdum ut volutpat eget, molestie quis mauris.",
      showText: false,
      label: "COMING SOON",
      blured: true,
    },
  ],
  about: {
    title: "About the Event",
    desc: `
<p>During this third event in the EXANTE Horizon series, a panel of&nbsp;international financial market experts will discuss how global&nbsp;markets have performed over the first three quarters of 2023 and&nbsp;explore the geopolitical events and policies influencing markets&nbsp;throughout 2023 and beyond.</p>
<p>Panellists will debate how the gravitational centre of European power and global influence is shifting eastwards. They will&nbsp;consider what this means for global, transatlantic and inter-European relationships focussing on trade, economic growth&nbsp;and the financial markets. We will explore how investors can&nbsp;manage emerging risks and find new investment opportunities.</p>
<strong>Event language: English with simultaneous Polish translation</strong>`,
    date: "14 September 2023",
    programme: `<ul>
  <li>
    <strong>3:30 PM<span>(GMT+2)</span></strong>
    <span>Panel discussion and Q&A</span>
  </li>
</ul>`,
  },
  benefits: [
    {
      name: "Ask the<br>experts",
      desc: "Join the discussion! Submit your questions in advance to get answers to your most pressing queries.",
      img: "experts-bg.jpg",
    },
    {
      name: "Gain<br>insights",
      desc: "Get a better understanding of the global, regional and local conditions that are moving markets.",
      img: "insights-bg.jpg",
    },
    {
      name: "Discover<br>investment opportunities",
      desc: "Which asset classes will rally and which will fizzle? Learn where there are investment opportunities and portfolio risks.",
      img: "investments-bg.jpg",
    },
  ],
  aboutPlatform: {
    desc_left:
      "The EXANTE Horizon programme provides a global platform for financial market discussions and&nbsp;networking opportunities.",
    desc_right:
      "We bring together experienced market analysts and ask them what is on the horizon for investors and traders.  We host offline and live streamed events in global financial hubs and burgeoning financial centres.<br><br>Bringing you the most relevant information and analysis, the Horizon programme helps you to see the bigger picture as you create your future financial horizon.",
  },
  readingRoom: {
    title: "Reading Room",
    desc: "We recognise that knowledge is power. As part of our Horizon series we are providing links to a variety of public sources that may be interesting and/or useful reference material in helping to understand what is affecting the performance of different asset classes.",
  },
}

export default DATA
