import Container from "../Container/Container"
import Row from "../Row/Row"
import Col from "../Col/Col"
import styles from "./About.module.scss"

export default function About() {
  return (
    <section className={styles.about} id="about">
      <Container className={styles.about__container}>
        <Row>
          <Col cols="24" lg="12" className={styles.about__head}>
            <h2>About EXANTE Horizon</h2>
            <p>
              EXANTE Horizon provides a platform for&nbsp;financial market
              discussions and&nbsp;networking.
            </p>
          </Col>
          <Col cols="24" lg="12" className={styles.about__desc}>
            <p>
              We bring together leading financial market experts in a series of
              global events to ask them what’s on the horizon for markets and
              which asset classes they are investing in. Horizon events take
              place in key financial hubs and burgeoning financial centres
              across the world. They are live streamed globally.
            </p>
            <p>
              By bringing you independent expert analysis and opinion, the
              Horizon series aims to provide you with a broader view that can
              better inform you and your clients investment decisions.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
